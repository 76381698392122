import * as Sentry from "@sentry/vue";
var urlArr = window.location.href.split('/');
var url = urlArr[0] + "//" + urlArr[2];

import axios from 'axios'
import router from '@/router/index'
import config from '@/helpers/config.js'
const https = require('https');
const agent = new https.Agent({
  rejectUnauthorized: false,
});
const instance = axios.create({
  baseURL: config.hostname,
  httpsAgent: agent,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json,',
    'Authorization': `${localStorage.getItem('token')}`,
  },
  crossdomain: true,
})


instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  if (!token) {
    return config;
  }
  var expirationDate = localStorage.getItem('expirationDate')
  var now = new Date()
  var currentTime = now.getTime();

  if (currentTime >= parseInt(expirationDate)) {
    Sentry.captureException("session expire");   
    sessionExpired();
  } else {
    return config;
  }
}, function (error) {
  // Do something with request error
  return Promise.reject(error);


});

instance.interceptors.response.use(function (response) {

  if (response.data.unauthorized == "unauthorized") {
    Sentry.captureException('unauthorized');   
    sessionExpired();
  }
  return response;
}, function (error) {
  // Do something with response error
  const status = error.response ? error.response.status : null
  if (status === 401 || status === 403) {
    Sentry.captureException("Do something with response error");   
    sessionExpired();
  }
  return Promise.reject(error);


});

function sessionExpired() {
  if (localStorage.getItem('token') != null && localStorage.getItem('token') != 'null') {
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('token')
    localStorage.removeItem('adminDetails')
    window.location.href = '/signin';
  } else {
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('token')
    localStorage.removeItem('adminDetails')
    router.push('/signin')
  }
}



export default instance
