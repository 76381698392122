import { defineStore } from 'pinia'

import router from '@/router/index'
import axios from '@/api/index'
import qs from 'qs'
import Nprogress from 'nprogress';
import  '../../node_modules/js-loading-overlay/dist/js-loading-overlay.min.js'
export const useloginStore = defineStore('loginstore', {
    state: () => {
      return {
        idAdminDetails: [],
        idToken: []
      }
    },
    getters: {
        
    },
    actions: {
      findLogin(authData) {
        console.log(app)
        JsLoadingOverlay.show({
            'spinnerIcon': 'ball-clip-rotate',
            "overlayOpacity": "0.6",
            "spinnerSize": "2x",
            "lockScroll": true,
        });
        return new Promise((resolve, reject) => {
            axios.post('/login/login_post', qs.stringify({
                username: authData.username,
                password: authData.password
            }, {
                parseArrays: false
            }))
                .then(res => {
                    JsLoadingOverlay.hide();
                    if (res.data.status != 'error') {
                        if (res.data.expirationData == undefined) {
                            location.reload()
                        }
                        var now = new Date()
                        var expirationDate = now.getTime() + 2 * 24 * 60 * 60 * 1000;
                        localStorage.setItem('token', res.data.message)
                        if (res.data.expirationData.exp != undefined) {
                            localStorage.setItem('expirationDate', res.data.expirationData.exp * 1000)
                        } else {
                            localStorage.setItem('expirationDate', expirationDate)
                        }

                        // commit('SET_USER_TOKEN', {
                        //     token: res.data.message
                        // })
                        this.idToken = res.data.message

                        if (res.data.paymentAlert == 'overdue') {
                            window.location.href = '/payment/blocked';
                            return;
                        }
                        if (res.data.resellerPayment == 'overdue') {
                            window.location.href = '/franchise_management/resellerBlocked';
                            return;
                        }
                        axios.get('/network_admins/get_admin_details', {
                            headers: {
                                'Authorization': `${localStorage.getItem('token')}`
                            }
                        })
                            .then(res => {
                                localStorage.setItem('adminDetails', res.data.adminDetails)
                                //commit('ADD_USER', res.data.adminDetails)
                                this.idAdminDetails = res.data.adminDetails
                                resolve(res.data)
                            })
                            .catch(error => console.log(error))
                            location.reload()
                    } else {
                        reject(res.data.message)
                    }
                })
                .catch(error => {
                    reject(error);
                    console.log(error)
                })
        })
      },
      findAdminOtpLogin(authData){
        JsLoadingOverlay.show({
            'spinnerIcon': 'ball-clip-rotate',
            "overlayOpacity": "0.6",
            "spinnerSize": "2x",
            "lockScroll": true,
        });
        return new Promise((resolve, reject) => {
            var now = new Date()
         var expirationDate = now.getTime() + 2 * 24 * 60 * 60 * 1000;
         localStorage.setItem('token', authData.adminMessage)
         if(authData.expirationDataExp != undefined){
            localStorage.setItem('expirationDate', authData.expirationDataExp * 1000)
         }else{
            localStorage.setItem('expirationDate', expirationDate)
         }
        //  commit('SET_USER_TOKEN', {
        //     token: authData.adminMessage
        // })
        this.idToken = authData.adminMessage

        if (authData.paymentAlert == 'overdue') {
            window.location.href = '/payment/blocked';
            return;
        }
        if (authData.resellerPayment == 'overdue') {
            window.location.href = '/franchise_management/resellerBlocked';
            return;
        }
        axios.get('/network_admins/get_admin_details', {
            headers: {
                'Authorization': `${localStorage.getItem('token')}`
            }
        })
            .then(res => {
                JsLoadingOverlay.hide();
                localStorage.setItem('adminDetails', res.data.adminDetails)
                //commit('ADD_USER', res.data.adminDetails)
                this.idAdminDetails = res.data.adminDetails

                resolve(res.data)
            })
            .catch(error => console.log(error))
        })

      },
      logout() {
        localStorage.removeItem('expirationDate')
        localStorage.removeItem('token')
        localStorage.setItem('token',"logout")
        localStorage.removeItem('adminDetails')
        this.idToken = null
        this.idAdminDetails = null        
        axios.get('/login/logout/true') 
            .then(res => {
                JsLoadingOverlay.hide();
                location.reload();
                resolve(res.data)
            })
            .catch(error => {
                location.reload();
                reject(error)
                console.log(error)
            })
      },
      findResetPassword(authData) {
        JsLoadingOverlay.show({
            'spinnerIcon': 'ball-clip-rotate',
            "overlayOpacity": "0.6",
            "spinnerSize": "2x",
            "lockScroll": true,
        });
        return new Promise((resolve, reject) => {
            axios.post('/reset_password', qs.stringify({
                email: authData.username,
                vue: 'true'
            }, {
                parseArrays: false
            }))
                .then(res => {
                    JsLoadingOverlay.hide();
                    if (res.data.status != 'success') {
                        reject(res.data.message)
                        return;
                    }
                    resolve(res.data.message)
                })
                .catch(error => {
                    reject(error)
                    console.log(error)
                })
        })

    },findResetCode( authData) {
        JsLoadingOverlay.show({
            'spinnerIcon': 'ball-clip-rotate',
            "overlayOpacity": "0.6",
            "spinnerSize": "2x",
            "lockScroll": true,
        });
        return new Promise((resolve, reject) => {
            axios.post('/reset_password/code', qs.stringify({
                password: authData.password,
                invite_code: authData.invitecode,
                vue: 'true'
            }, {
                parseArrays: false
            }))
                .then(res => {
                    JsLoadingOverlay.hide();

                    resolve(res.data)
                })
                .catch(error => {
                    reject(error)
                    console.log(error)
                })
        })

    },


     
     
      }
})